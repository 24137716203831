<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 12:55:51
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:27:39
 * @FilePath: \crm-education\src\views\student\my_textbook_send\c\AddressModifyDialog.vue
-->
<template>
  <a-modal
    width="520px"
    class="cus-modal address-modify-dialog"
    v-model="visible"
    :footer="false"
  >
    <template slot="title">
      <p class="text-center">修改收件地址</p>
    </template>
    <a-form class="cus-form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="收件人姓名">
        <a-input
          v-decorator="[
            'field1',
            {
              rules: [
                {
                  required: true,
                  message: '必填',
                },
              ],
            },
          ]"
          placeholder="填写收件人姓名"
        ></a-input>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
          v-decorator="[
            'field2',
            {
              rules: [
                {
                  required: true,
                  message: '必填',
                },
              ],
            },
          ]"
          placeholder="填写手机号"
        ></a-input>
      </a-form-item>
      <a-form-item label="收件地址">
        <a-input
          v-decorator="[
            'field3',
            {
              rules: [
                {
                  required: true,
                  message: '必填',
                },
              ],
            },
          ]"
          placeholder="填写收件地址"
        ></a-input>
      </a-form-item>
      <div class="text-center py-10">
        <a-button type="primary" html-type="submit" class="cus-button"
          >保存修改</a-button
        >
      </div>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'address_modify_dialog',
  data () {
    return {
      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    }
  },
  methods: {
    open () {
      this.visible = true
    }
  }
}
</script>
