<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 12:38:26
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 12:54:07
 * @FilePath: \crm-education\src\views\student\my_textbook_send\c\DeliveryInfoDialog.vue
-->
<template>
  <a-modal width="720px" class="cus-modal delivery-info-dialog" v-model="visible" :footer="false">
    <template slot="title">
      <p class="text-center">物流详情</p>
    </template>
    <div class="delivery-info">
      <div class="delivery-info-item d-flex align-start py-10">
        <p class="fs-14 color-gray">快递公司：</p>
        <p class="fs-14 pl-10">{{ this.info.kuaidiName }}</p>
      </div>
      <div class="delivery-info-item d-flex align-start py-10">
        <p class="fs-14 color-gray">物流单号：</p>
        <p class="fs-14 pl-10">{{ this.info.trackingNumber }}</p>
      </div>
      <div class="delivery-info-item d-flex align-start py-10">
        <p class="fs-14 color-gray">物流跟踪：</p>
        <div class="fs-14 pl-10 pt-10">
          <a-timeline>
            <a-timeline-item color="gray" v-for="item in deliveryList" :key="item.dateTime">
              <div class="delivery-date-item d-flex align-start">
                <p class="day fw-bold fs-14">{{ item.detailTime | getDay }}</p>
                <div class="delivery-step-wrap">
                  <div
                    class="delivery-step-item  d-flex align-start mb-10"
                    v-for="subItem in item.children"
                    :key="subItem.detailTime"
                  >
                    <p class="time fs-14 pl-5">{{ subItem.detailTime | getTime }}</p>
                    <p class="context fs-14 pl-20">{{ subItem.detailContext }}</p>
                  </div>
                </div>
              </div>
            </a-timeline-item>
          </a-timeline>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import moment from 'moment'

export default {
  name: 'delivery_info_dialog',
  data() {
    return {
      visible: false,
      info: {}
    }
  },
  computed: {
    deliveryList() {
      return this.getDeliveryRenderData(this.info.detailList)
    }
  },
  methods: {
    open(info = {}) {
      this.info = info
      this.visible = true
    },
    getDeliveryRenderData(list = []) {
      const renderList = []
      if (list && list.length === 0) {
        return renderList
      }
      list.forEach(item => {
        const dateTime = new Date(item.detailTime)
        const isExitDay = renderList.find(d => {
          return moment(new Date(d.detailTime)).isSame(moment(dateTime), 'day')
        })
        if (isExitDay) {
          isExitDay.children.push(item)
          isExitDay.children = isExitDay.children.sort((a, b) => {
            console.log(moment(new Date(a.detailTime)).isBefore(moment(new Date(b.detailTime))))
            return moment(new Date(a.detailTime)).isBefore(moment(new Date(b.detailTime)))
          })
        } else {
          const renderItem = {
            detailTime: item.detailTime,
            children: []
          }
          renderItem.children.push(item)
          renderList.push(renderItem)
        }
      })
      return renderList.sort((a, b) => {
        return moment(new Date(a.detailTime)).isBefore(moment(new Date(b.detailTime)))
      })
    }
  },
  filters: {
    getDay(val) {
      return moment(new Date(val)).format('yyyy-MM-DD')
    },
    getTime(val) {
      return moment(new Date(val)).format('HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
.delivery-date-item {
  .day {
    width: 80px;
  }
  .delivery-step-wrap {
    flex: 1;
    .time {
      width: 70px;
    }
    .context {
      flex: 1;
    }
  }
}
</style>
