<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 12:24:51
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:03:04
 * @FilePath: \crm-education\src\views\student\my_textbook_send\c\TextBookItem.vue
-->
<template>
  <a-card class="cus-card no-border" :bordered="false">
    <template slot="title">
      <div class="section-header dec d-flex align-center justify-between flex-wrap">
        <h2 class="base-title fs-18">{{ info.termMsg }}</h2>
        <p class="fs-14 color-gray link hover-color-blue">
          <a-tooltip placement="bottom">
            <template slot="title">
              请点击左侧“个人信息”修改“通讯地址"，已寄出状态的教材收件地址无法进行变更。
            </template>
            修改收件地址
          </a-tooltip>
        </p>
      </div>
    </template>
    <a-form class="cus-form">
      <a-row type="flex" align="middle">
        <a-col :md="{ span: 4 }" :xs="{ span: 24 }">
          <a-form-item label="收件人姓名">{{ info.toName }}</a-form-item>
        </a-col>
        <!-- TODO:字段缺失 -->
        <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
          <a-form-item label="手机号码">{{ info.mobilePhone }}</a-form-item>
        </a-col>
        <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
          <a-form-item label="收件地址">{{ info.toAddress }}</a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" align="middle">
        <a-col :md="{ span: 4 }" :xs="{ span: 24 }">
          <a-form-item label="快递公司">{{ info.kuaidiName }}</a-form-item>
        </a-col>
        <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
          <a-form-item label="物流单号">{{ info.trackingNumber }}</a-form-item>
        </a-col>
        <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
          <a-form-item label="签收状态"
            ><span :class="getStateClasses(info.logisticsState)">{{
              info.logisticsState | getState
            }}</span></a-form-item
          >
        </a-col>
      </a-row>
      <div class="text-center">
        <a-button class="cus-button" type="primary" @click="onDeliveryInfo">物流详情</a-button>
      </div>
      <delivery-info-dialog ref="deliveryInfoDialog"></delivery-info-dialog>
      <address-modify-dialog ref="addressModifyDialog"></address-modify-dialog>
    </a-form>
  </a-card>
</template>

<script>
import api from '@/api'
import DeliveryInfoDialog from './DeliveryInfoDialog'
import AddressModifyDialog from './AddressModifyDialog'
export default {
  name: 'textbook_item',
  components: {
    DeliveryInfoDialog,
    AddressModifyDialog
  },
  props: {
    info: {
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    async onDeliveryInfo() {
      // TODO: 查看物流相求
      const params = {
        trackingNumber: this.info.trackingNumber
      }
      let res = await api.student.getDeliveInfo(params)
      if (res.code === 200) {
        this.$refs.deliveryInfoDialog.open(res.data)
      }
    },
    // onModifyAddress() {
    //   this.$refs.addressModifyDialog.open()
    // },
    getStateClasses(val) {
      switch (String(val)) {
        case '0':
          return ''
        case '1':
          return ''
        case '2':
          return 'color-orange-light'
      }
    }
  },
  filters: {
    getState(val) {
      switch (String(val)) {
        case '0':
          return '已揽收'
        case '1':
          return '运送中'
        case '2':
          return '已签收'
        case '3':
          return '签收'
        case '4':
          return '退签'
        case '5':
          return '派件'
        case '6':
          return '退回'
        case '7':
          return '转单'
        case '10':
          return '待清关'
        case '11':
          return '清关中'
        case '12':
          return '已清关'
        case '13':
          return '清关异常'
        case '14':
          return '收件人拒签'
        default:
          return ''
      }
    }
  }
}
</script>
