<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 12:23:52
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:33:09
 * @FilePath: \crm-education\src\views\student\my_textbook_send\index.vue
-->
<template>
  <div class="my-textbook-send">
    <template v-if="textBookInfo.length">
      <div class="mb-10" v-for="info in textBookInfo" :key="info.id">
        <text-book-item :info="info"></text-book-item>
      </div>
    </template>
    <a-card class="cus-card no-border py-10" :bordered="false" v-else>
      <a-empty>
        <span slot="description"> 暂无数据</span>
      </a-empty>
    </a-card>
  </div>
</template>

<script>
import TextBookItem from './c/TextBookItem'
// import helper from '@/utils/helper'
import api from '@/api'
export default {
  name: 'my_textbook_send',
  components: {
    TextBookItem
  },
  data() {
    return {
      textBookInfo: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.initTextBook()
    },
    async initTextBook() {
      let res = await api.student.getTextBook()
      if (res.code === 200) {
        this.textBookInfo = res.data
      }
    }
  }
}
</script>
