var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"cus-modal address-modify-dialog",attrs:{"width":"520px","footer":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"title"},[_c('p',{staticClass:"text-center"},[_vm._v("修改收件地址")])]),_c('a-form',{staticClass:"cus-form",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"label":"收件人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'field1',
          {
            rules: [
              {
                required: true,
                message: '必填',
              },
            ],
          },
        ]),expression:"[\n          'field1',\n          {\n            rules: [\n              {\n                required: true,\n                message: '必填',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"填写收件人姓名"}})],1),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'field2',
          {
            rules: [
              {
                required: true,
                message: '必填',
              },
            ],
          },
        ]),expression:"[\n          'field2',\n          {\n            rules: [\n              {\n                required: true,\n                message: '必填',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"填写手机号"}})],1),_c('a-form-item',{attrs:{"label":"收件地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'field3',
          {
            rules: [
              {
                required: true,
                message: '必填',
              },
            ],
          },
        ]),expression:"[\n          'field3',\n          {\n            rules: [\n              {\n                required: true,\n                message: '必填',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"填写收件地址"}})],1),_c('div',{staticClass:"text-center py-10"},[_c('a-button',{staticClass:"cus-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存修改")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }